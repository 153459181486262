<template>
  <div class="popupView" id="popupView">
    <div @click="popupShow">
      <slot name="default">
        <div>查看</div>
      </slot>
    </div>
    <van-popup v-model="pageData.popupViewShow" round closeable position="bottom" teleport="#popupView">
      <div class="title">
        {{ title }}
      </div>
      <div class="viewContent">
        <div v-if="pageData.pdfLoading" class="pdf-content">
          <pdf v-for="i in numPages" :key="i" :src="pdfSrc" :page="i"></pdf>
        </div>
        <div v-else class="h-text-center h-m-t-30">
          <van-loading type="spinner" color="#1989fa" size="30px">文件加载中...</van-loading>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import pdf from "./pdf";
export default {
  name: "PdfPopup",
  components: { pdf },
  props: {
    title: {
      type: String,
      default: "预览信息"
    },
    pdfUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      pageData: {
        popupViewShow: false,
        pdfLoading: false
      },
      pdfSrc: null,
      numPages: 0
    };
  },
  mounted() {
    if (!this.pdfUrl) {
      this.$toast("合同链接不能为空");
      return false;
    }
    const loadingTask = pdf.createLoadingTask({
      url: this.pdfUrl.replace("getFile", "preview"),
      // 引入pdf.js字体，templ
      cMapUrl: "/pdf/web/cmaps/",
      cMapPacked: true
    });
    this.pdfSrc = loadingTask;
    this.pdfSrc.promise
      .then(pdf => {
        this.pageData.pdfLoading = true;
        this.numPages = pdf.numPages;
      })
      .catch(error => {
        this.$toast("获取pdf文件失败：" + error.message);
      });
  },
  methods: {
    popupShow() {
      this.pageData.popupViewShow = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.popupView {
  .van-popup {
    height: 90vh;
  }
  .title {
    font-size: $font-size-md;
    height: 50px;
    line-height: 50px;
    text-align: center;
    white-space: nowrap;
    border-bottom: 1px dashed $primary-color;
  }
  .viewContent {
    position: relative;
    height: calc(100% - 50px);
    overflow: scroll;
  }
}
</style>
