<template>
  <div class="h-backgroud-color_body">
    <div v-if="fileLayout" class="h-p-5">
      <template v-for="imgItem in fileLayout">
        <div :key="imgItem.prop" class="form h-shadow-sm">
          <div class="form-title h-flex h-flex-wrap" :class="{ 'form-title--required': imgItem.required }">
            <div class="title">{{ imgItem.label }}</div>
            <div v-if="imgItem.tip">
              <van-popover v-model="imgItem.tipPopover" theme="dark" placement="top" trigger="click">
                <div class="h-font-sm h-p-4">{{ imgItem.tip }}</div>
                <template #reference>
                  <van-icon name="info" color="#57ccc2" />
                </template>
              </van-popover>
            </div>
          </div>
          <div class="form-content">
            <HUpload
              :key="imgItem.prop"
              :config="imgItem"
              :value="pageForm.fileRecords[imgItem.prop]"
              @change="
                imgs => {
                  handleImagChange(imgItem.prop, imgs);
                }
              "
            ></HUpload>
          </div>
        </div>
      </template>
    </div>
    <div class="h-flex">
      <div class="back h-flex-1 h-text-center h-flex">
        <van-button :loading="pageData.submitLoading" plain block type="primary" @click="pre">上一步</van-button>
      </div>
      <van-button :loading="pageData.submitLoading" loading-text="提交中..." class="h-flex-1" block type="primary" @click="showVerify">提交</van-button>
    </div>
    <van-popup v-model="pageData.showVerifyDialog" position="bottom" round closeable :style="{ maxHeight: '90%' }">
      <div class="h-p-15 h-p-r-40"></div>
      <div>
        <div class="h-p-v-10 h-p-h-20 h-font-md">&nbsp;&nbsp;为了确保【{{ productName }}】服务权益人知晓本次履约申请，点击【<span class="h-primary-color">获取验证码</span>】，将给手机号{{ phone }}发送验证码，验证后可提交申请。</div>
        <div class="h-p-b-30">
          <van-field v-model="sendData.smsCode" type="digit" center clearable label="验证码" label-width="4em" placeholder="请输入验证码" maxlength="6">
            <template #button>
              <van-button size="small" plain :disabled="!sendData.isCanSendCode" :loading="pageData.sendLoading" loading-text="发送中..." type="primary" @click="getSmsCode">{{ sendData.sendMsg }}</van-button>
            </template>
          </van-field>
        </div>
      </div>
      <div class="h-flex">
        <van-button class="h-flex-1" block type="primary" :loading="pageData.submitLoading" @click="submit">确认</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import HUpload from "@/components/Form/items/HUpload.vue";
import { mapState } from "vuex";
import { request } from "@/api/service";
import dayjs from "dayjs";
export default {
  name: "FileClaim",
  components: { HUpload },
  inject: {
    pageForm: {
      type: Object,
      default: null
    },
    pageLayout: {
      type: Array,
      default: null
    },
    pageLayoutMap: {
      type: Object,
      default: null
    },
    PageScript: {
      type: Function,
      default: null
    },
    pagePre: {
      type: Function,
      default: null
    },
    pageNext: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      pageData: {
        submitLoading: false,
        showVerifyDialog: false,
        sendLoading: false
      },
      sendData: {
        timer: null,
        isCanSendCode: true,
        sendMsg: "获取验证码",
        smsCode: null
      },
      productCode: null,
      fileLayout: [],
      fileForm: {}
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      return pageScriptObj;
    },
    phone() {
      if (this.pageForm.extend && this.pageForm.extend.customer && this.pageForm.extend.customer.phone) {
        return this.pageForm.extend.customer.phone.replace(/(\d{3})\d*(\d{4})/, "$1****$2");
      } else {
        return null;
      }
    },
    productName() {
      if (this.pageForm.extend && this.pageForm.extend.coverage) {
        return this.pageForm.extend.coverage.productName;
      } else {
        return null;
      }
    }
  },
  created() {
    const { productCode } = this.$route.query;
    this.productCode = productCode;
    this.initData();
    this.initFileLayout();
    window.scrollTo(0, 0);
  },
  methods: {
    dayjs,
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initFileLayout() {
      if (!(this.pageLayout && this.pageLayout.length > 0)) {
        return false;
      }
      const filePageLayout = this.pageLayout.filter(res => res.fmCode === "fileRecords");
      if (!(filePageLayout && filePageLayout.length > 0)) {
        return false;
      }
      filePageLayout.forEach(item => {
        if (!item.formItems) {
          return false;
        }
        this.fileLayout = this.fileLayout.concat(item.formItems);
      });
    },
    handleImagChange(code, images) {
      this.pageForm.fileRecords[code] = images;
    },
    pre() {
      this.$router.go(-1);
      // this.pagePre({code:this.productCode,id:this.pageForm.report.id})
    },
    setCodeInterval() {
      // 设置验证码重新发送定时器
      let time = 60;
      this.sendData.timer = setInterval(() => {
        time--;
        this.sendData.isCanSendCode = false;
        this.sendData.sendMsg = time + "秒后重试";
        if (time <= 0) {
          this.sendData.isCanSendCode = true;
          this.clearCodeInterval();
        }
      }, 1000);
    },
    clearCodeInterval() {
      clearInterval(this.sendData.timer);
      this.sendData.sendMsg = "重新获取";
    },
    getSmsCode() {
      this.pageData.sendLoading = true;
      request({ url: `/afis-carservice/claim/verificationcode/${this.pageForm.contractId}`, method: "post", data: { contractId: this.pageForm.contractId } })
        .then(res => {
          this.$toast("获取验证码成功");
          this.setCodeInterval();
          this.pageData.sendLoading = false;
        })
        .catch(error => {
          this.pageData.sendLoading = false;
          this.$toast(error.message || "获取验证码失败,请稍后重试");
        });
    },
    showVerify() {
      // eslint-disable-next-line guard-for-in
      for (const key in this.fileLayout) {
        const fileItem = this.fileLayout[key];
        if (fileItem.required && (!this.pageForm.fileRecords[fileItem.prop] || this.pageForm.fileRecords[fileItem.prop].length < 1)) {
          this.$notify({ type: "danger", message: `请上传【${fileItem.label}】影像` });
          return false;
        }
      }
      this.pageData.submitLoading = true;
      const submitForm = this.scriptObj.pageToService();
      request({
        url: "/afis-carservice/claim/check",
        method: "POST",
        data: submitForm
      })
        .then(res => {
          this.pageData.submitLoading = false;
          if (this.productCode === "ASDBWY") {
            this.submit({ code: null });
          } else {
            this.pageData.showVerifyDialog = true;
          }
        })
        .catch(error => {
          this.pageData.submitLoading = false;
          this.$dialog.alert({ title: "申请提示", message: error.message }).then(() => {});
        });
    },
    async submit() {
      // eslint-disable-next-line guard-for-in
      for (const key in this.fileLayout) {
        const fileItem = this.fileLayout[key];
        if (fileItem.required && (!this.pageForm.fileRecords[fileItem.prop] || this.pageForm.fileRecords[fileItem.prop].length < 1)) {
          this.$notify({ type: "danger", message: `请上传【${fileItem.label}】影像` });
          return false;
        }
      }
      if (!this.sendData.smsCode && this.productCode !== "ASDBWY") {
        this.$toast("请输入验证码");
        return false;
      }
      // 短信验证码校验
      request({
        url: "/afis-carservice/claim/verificationcode/check",
        method: "post",
        data: {
          contractId: this.pageForm.contractId,
          coverageId: this.pageForm.coverageId,
          phoneCode: this.sendData.smsCode
        }
      }).then(() => {
        this.$dialog
          .confirm({
            title: "是否确认提交",
            message: "",
            confirmButtonText: "确认"
          })
          .then(() => {
            this.pageData.submitLoading = true;
            const submitForm = this.scriptObj.pageToService();
            submitForm.phoneCode = this.sendData.smsCode;
            this.$toast.loading({
              duration: 0,
              message: "提交中...",
              forbidClick: true
            });
            if (this.productCode === "ASCXDB") {
              submitForm.claimType = this.claimType;
            }
            request({
              url: "/afis-carservice/claim/commonSave",
              method: "POST",
              data: submitForm
            })
              .then(res => {
                this.pageData.submitLoading = false;
                this.$toast.clear();
                this.pageNext({ productCode: this.productCode, id: res.id, claimNo: res.claimNo }, true);
              })
              .catch(error => {
                this.pageData.submitLoading = false;
                this.$toast.clear();
                this.$dialog.alert({ title: "申请提示", message: error.message }).then(() => {});
              });
          })
          .catch(error => {});
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  &-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    &--required {
      overflow: visible;
      &::before {
        position: absolute;
        left: 14px;
        color: #ee0a24;
        font-size: 14px;
        content: "*";
      }
    }
    .title {
      border-left: 5px solid $primary-color;
      padding: 0 8px;
    }
  }
  &-content {
    padding: 10px;
  }
}
.back {
  height: 44px;

  background-color: $white;
}
</style>
